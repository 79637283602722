import { Card, Col, Row } from 'antd';
import Search from 'antd/lib/transfer/search';
import axios from 'axios';
import React, { useCallback, useState, useEffect } from 'react';
// import { useState } from 'react/cjs/react.development';

// import baseApi from '../CERTIFICATES/baseApi';
import './style.css'


// const data1 = [
//     {
//         name: "I_ID_51_O Karabaev, M Toshkuziev",
//         src: "/assets/files/Acceptances IA_1/I_ID_51_O Karabaev, M Toshkuziev.pdf"
//     },
//     {
//         name: "I_ID_52_J Gafurov, K Katsura, A Kayumov, Z Ismoilov, Y Hanayoshi, Y Yoda",
//         src: "/assets/files/Acceptances IA_1/I_ID_52_J Gafurov, K Katsura, A Kayumov, Z Ismoilov, Y Hanayoshi, Y Yoda.pdf"
//     },
//     {
//         name: "I_ID_53_Z Tilyabayev, M Xashimova, R Esanov, M Gafurov, A Matchanov and K Rustamov",
//         src: "/assets/files/Acceptances IA_1/I_ID_53_Z Tilyabayev, M Xashimova, R Esanov, M Gafurov, A Matchanov and K Rustamov.pdf"
//     },
//     {
//         name: "I_ID_55_S Toshpulatov, N Avilova",
//         src: "/assets/files/Acceptances IA_1/I_ID_55_S Toshpulatov, N Avilova.pdf"
//     },
//     {
//         name: "I_ID_56_M Mirusmanov, A Tangirov, Z Xaydarov, S Sodiqov, B Urunov and T Ishbaboyev",
//         src: "/assets/files/Acceptances IA_1/I_ID_56_M Mirusmanov, A Tangirov, Z Xaydarov, S Sodiqov, B Urunov and T Ishbaboyev.pdf"
//     },
//     {
//         name: "I_ID_57_N Avilova, R Oymatov",
//         src: "/assets/files/Acceptances IA_1/I_ID_57_N Avilova, R Oymatov.pdf"
//     }, {
//         name: "I_ID_58_A Bairov, N Abdurakhmonov, Sh Juraev and Kh Nuriddinova",
//         src: "/assets/files/Acceptances IA_1/I_ID_58_A Bairov, N Abdurakhmonov, Sh Juraev and Kh Nuriddinova.pdf"
//     },
//     {
//         name: "I_ID_59_B Primqulov ,  A îamataliyev  and U Temirov",
//         src: "/assets/files/Acceptances IA_1/I_ID_59_B Primqulov ,  A îamataliyev  and U Temirov.pdf"
//     },
//     {
//         name: "I_ID_60_E.V. Nikitina, Kh. U. Khalbekova",
//         src: "/assets/files/Acceptances IA_1/I_ID_60_E.V. Nikitina, Kh. U. Khalbekova.pdf"
//     },
//     {
//         name: "I_ID_61_E.V. Nikitina, N. Yu. Beshko, S.A. Omarov",
//         src: "/assets/files/Acceptances IA_1/I_ID_61_E.V. Nikitina, N. Yu. Beshko, S.A. Omarov.pdf"
//     },
//     {
//         name: "I_ID_62_D Mikhaylov,  Z Iskandarov, E Grudovich,  I Rakovec,",
//         src: "/assets/files/Acceptances IA_1/I_ID_62_D Mikhaylov,  Z Iskandarov, E Grudovich,  I Rakovec,.pdf"
//     },
//     {
//         name: "I_ID_63_B  Saipova, O Ruzikulova, D Djuraeva and A Altiev",
//         src: "/assets/files/Acceptances IA_1/I_ID_63_B  Saipova, O Ruzikulova, D Djuraeva and A Altiev.pdf"
//     }, {
//         name: "I_ID_64_M Djiyanov,  I Tadjibekova and N Temirkulova",
//         src: "/assets/files/Acceptances IA_1/I_ID_64_M Djiyanov,  I Tadjibekova and N Temirkulova.pdf"
//     },
//     {
//         name: "I_ID_65_A Utepbergenov, R Satbaeva",
//         src: "/assets/files/Acceptances IA_1/I_ID_65_A Utepbergenov, R Satbaeva.pdf"
//     },
//     {
//         name: "I_ID_66_Z Nosirova, L Xalmirzayeva",
//         src: "/assets/files/Acceptances IA_1/I_ID_66_Z Nosirova, L Xalmirzayeva.pdf"
//     },
//     {
//         name: "I_ID_67_T Shermuxammedov, A Khudaykulov B Zhonibekov and N Tulyaganova",
//         src: "/assets/files/Acceptances IA_1/I_ID_67_T Shermuxammedov, A Khudaykulov B Zhonibekov and N Tulyaganova.pdf"
//     },
//     {
//         name: "I_ID_68_S Shamuratov, U Baltaev, O Myachina, U Alimov and T Kuramboyev",
//         src: "/assets/files/Acceptances IA_1/I_ID_68_S Shamuratov, U Baltaev, O Myachina, U Alimov and T Kuramboyev.pdf"
//     },
//     {
//         name: "I_ID_69_L Mamasaliyeva, R Kim, T Azizov, M Ibragimova, B Pulatov, A Raxmonov and Sh Isxakova",
//         src: "/assets/files/Acceptances IA_1/I_ID_69_L Mamasaliyeva, R Kim, T Azizov, M Ibragimova, B Pulatov, A Raxmonov and Sh Isxakova.pdf"
//     }, {
//         name: "I_ID_70_O Abdujalilova, N Paxradinova",
//         src: "/assets/files/Acceptances IA_1/I_ID_70_O Abdujalilova, N Paxradinova.pdf"
//     },
//     {
//         name: "I_ID_71_K Kondrasheva, F Egamberdiev, R Suyarova, D Ruzieva, S Nasmetova, L Abdulmyanova, G Rasulova and T Gulyamova",
//         src: "/assets/files/Acceptances IA_1/I_ID_71_K Kondrasheva, F Egamberdiev, R Suyarova, D Ruzieva, S Nasmetova, L Abdulmyanova, G Rasulova and T Gulyamova.pdf"
//     },
//     {
//         name: "I_ID_72_G Sodikova, E K«dirov, Ch Kashkaboeva and B Kodirov",
//         src: "/assets/files/Acceptances IA_1/I_ID_72_G Sodikova, E K«dirov, Ch Kashkaboeva and B Kodirov.pdf"
//     },
//     {
//         name: "I_ID_73_M Qurbonov, L Gafurova, R Madrimov",
//         src: "/assets/files/Acceptances IA_1/I_ID_73_M Qurbonov, L Gafurova, R Madrimov.pdf"
//     },
//     {
//         name: "I_ID_74_R Qurvantayev, A Musurmanov, Z  Xalilova and N Soliyeva",
//         src: "/assets/files/Acceptances IA_1/I_ID_74_R Qurvantayev, A Musurmanov, Z  Xalilova and N Soliyeva.pdf"
//     },
//     {
//         name: "I_ID_75_B Baltayev, S Boltayev",
//         src: "/assets/files/Acceptances IA_1/I_ID_75_B Baltayev, S Boltayev.pdf"
//     }, {
//         name: "I_ID_76_A Anorboyev, A Jumayeva",
//         src: "/assets/files/Acceptances IA_1/I_ID_76_A Anorboyev, A Jumayeva.pdf"
//     },
//     {
//         name: "I_ID_77_M Ermatova, S Sidiqov, Kh Rakhimov, Z Saydullaeva",
//         src: "/assets/files/Acceptances IA_1/I_ID_77_M Ermatova, S Sidiqov, Kh Rakhimov, Z Saydullaeva.pdf"
//     },
//     {
//         name: "I_ID_78_M Abduraxmanova",
//         src: "/assets/files/Acceptances IA_1/I_ID_78_M Abduraxmanova.pdf"
//     },
//     {
//         name: "I_ID_79_N Raupova, Z Gulamova, B Azimov, L Abdukadirova and M Mansurova",
//         src: "/assets/files/Acceptances IA_1/I_ID_79_N Raupova, Z Gulamova, B Azimov, L Abdukadirova and M Mansurova.pdf"
//     },
//     {
//         name: "I_ID_80_M Radkevich, F Mukhammadaliyeva, K Shipilova, N Umarova  and A Gapirov",
//         src: "/assets/files/Acceptances IA_1/I_ID_80_M Radkevich, F Mukhammadaliyeva, K Shipilova, N Umarova  and A Gapirov.pdf"
//     },
//     {
//         name: "I_ID_81_I Urazbayev, K Fayziyev",
//         src: "/assets/files/Acceptances IA_1/I_ID_81_I Urazbayev, K Fayziyev.pdf"
//     }, {
//         name: "I_ID_83_M Sennikov, G Omarova, B Matyakubov, Kh Tursunbayev and A Atajanov",
//         src: "/assets/files/Acceptances IA_1/I_ID_83_M Sennikov, G Omarova, B Matyakubov, Kh Tursunbayev and A Atajanov.pdf"
//     },
//     {
//         name: "I_ID_84_D Yodgorova, N Atabayeva, M Kamalova and Sh Isxakova",
//         src: "/assets/files/Acceptances IA_1/I_ID_84_D Yodgorova, N Atabayeva, M Kamalova and Sh Isxakova.pdf"
//     },
//     {
//         name: "I_ID_85_D Majidova, M Rakhimov, Z Elmuratova",
//         src: "/assets/files/Acceptances IA_1/I_ID_85_D Majidova, M Rakhimov, Z Elmuratova.pdf"
//     },
//     {
//         name: "I_ID_86_A Turdaliev, D Darmonov, N Teshaboyev, A Saminov, and M Abdurakhmonova",
//         src: "/assets/files/Acceptances IA_1/I_ID_86_A Turdaliev, D Darmonov, N Teshaboyev, A Saminov, and M Abdurakhmonova.pdf"
//     },

//     {
//         name: "I_ID_87_S Isaev,  A Juraev, U Juraev,  O Murodov,  M Ruziyeva",
//         src: "/assets/files/Acceptances IA_1/I_ID_87_S Isaev,  A Juraev, U Juraev,  O Murodov,  M Ruziyeva.pdf"
//     },
//     {
//         name: "I_ID_88_M Khamidov, A Juraev, U Juraev, B Atamuradov, K Rustamova",
//         src: "/assets/files/Acceptances IA_1/I_ID_88_M Khamidov, A Juraev, U Juraev, B Atamuradov, K Rustamova.pdf"
//     }, {
//         name: "I_ID_89_A Juraev, U Juraev, U Saksonov, X Buriev",
//         src: "/assets/files/Acceptances IA_1/I_ID_89_A Juraev, U Juraev, U Saksonov, X Buriev.pdf"
//     },
//     {
//         name: "I_ID_90_Yu Kenjaev",
//         src: "/assets/files/Acceptances IA_1/I_ID_90_Yu Kenjaev.pdf"
//     },
//     {
//         name: "I_ID_91_J Eshonkulov, B Kamilov",
//         src: "/assets/files/Acceptances IA_1/I_ID_91_J Eshonkulov, B Kamilov.pdf"
//     },
//     {
//         name: "I_ID_92_G Mirkhaydarova, G Raimbaeva",
//         src: "/assets/files/Acceptances IA_1/I_ID_92_G Mirkhaydarova, G Raimbaeva.pdf"
//     },
//     {
//         name: "I_ID_93_A Turdaliev, K Askarov",
//         src: "/assets/files/Acceptances IA_1/I_ID_93_A Turdaliev, K Askarov.pdf"
//     },
//     {
//         name: "I_ID_94_O Jabborov, M Sodiqova, D Makhkamova and A Ruziev",
//         src: "/assets/files/Acceptances IA_1/I_ID_94_O Jabborov, M Sodiqova, D Makhkamova and A Ruziev.pdf"
//     }, {
//         name: "I_ID_95_Sh Djabborov, B Azimov, B Qo'shaqboyev and M Musurmonova",
//         src: "/assets/files/Acceptances IA_1/I_ID_95_Sh Djabborov, B Azimov, B Qo'shaqboyev and M Musurmonova.pdf"
//     },
//     {
//         name: "I_ID_96_Z Abdikulov",
//         src: "/assets/files/Acceptances IA_1/I_ID_96_Z Abdikulov.pdf"
//     },
//     {
//         name: "I_ID_97_A Mahkamova,  B Kamilov",
//         src: "/assets/files/Acceptances IA_1/I_ID_97_A Mahkamova,  B Kamilov.pdf"
//     },
//     {
//         name: "I_ID_98_M Tashkuziyev, S Ochilov, T Berdiyev, O Karabekov, X Karimov and J Atamuratov",
//         src: "/assets/files/Acceptances IA_1/I_ID_98_M Tashkuziyev, S Ochilov, T Berdiyev, O Karabekov, X Karimov and J Atamuratov.pdf"
//     },
//     {
//         name: "I_ID_99_S Nizamov, G Bezborodov, M Ziyatov and K Katsura",
//         src: "/assets/files/Acceptances IA_1/I_ID_99_S Nizamov, G Bezborodov, M Ziyatov and K Katsura.pdf"
//     },
//     {
//         name: "I_ID_101_B Kahorov, D Zainitdinova, S Rasulova and F Xaitova",
//         src: "/assets/files/Acceptances IA_1/I_ID_101_B Kahorov, D Zainitdinova, S Rasulova and F Xaitova.pdf"
//     }, {
//         name: "I_ID_102_B Zaripov, B Kakharov, M Allamuratov, G Akhmedova and B Niyazmetov",
//         src: "/assets/files/Acceptances IA_1/I_ID_102_B Zaripov, B Kakharov, M Allamuratov, G Akhmedova and B Niyazmetov.pdf"
//     },
//     {
//         name: "I_ID_103_U Norkulov, B Izbasarov, B Tukhtashev and J Eshonkulov",
//         src: "/assets/files/Acceptances IA_1/I_ID_103_U Norkulov, B Izbasarov, B Tukhtashev and J Eshonkulov.pdf"
//     },
//     {
//         name: "I_ID_104_G Abdalova , N Khudaybergenov, J Eshonkulov",
//         src: "/assets/files/Acceptances IA_1/I_ID_104_G Abdalova , N Khudaybergenov, J Eshonkulov.pdf"
//     },
//     {
//         name: "I_ID_105_A Rasulov, B Sultonov, M Kirgizova, S Tursunova and A Gapparov",
//         src: "/assets/files/Acceptances IA_1/I_ID_105_A Rasulov, B Sultonov, M Kirgizova, S Tursunova and A Gapparov.pdf"
//     },

//     {
//         name: "I_ID_106_D Kholdarov, G Yuldashev, A Rakhimov and Z Azimov",
//         src: "/assets/files/Acceptances IA_1/I_ID_106_D Kholdarov, G Yuldashev, A Rakhimov and Z Azimov.pdf"
//     },
//     {
//         name: "I_ID_107_M Saidova, L Gafurova, D Kodirova, N Shadiyeva,  O Ergasheva and  N Qo'chqorova",
//         src: "/assets/files/Acceptances IA_1/I_ID_107_M Saidova, L Gafurova, D Kodirova, N Shadiyeva,  O Ergasheva and  N Qo'chqorova.pdf"
//     },




//     {
//         name: "S-ID-01_A. Inamov and V. Nilipovskiy",
//         src: "/assets/files/Acceptances KS/S-ID-01_A. Inamov and V. Nilipovskiy.pdf"
//     },
//     {
//         name: "S-ID-02_A. Raupov, A. Aminov and L. Igamberdieva",
//         src: "/assets/files/Acceptances KS/S-ID-02_A. Raupov, A. Aminov and L. Igamberdieva.pdf"
//     },
//     {
//         name: "S-ID-03_A. Saydalimov",
//         src: "/assets/files/Acceptances KS/S-ID-03_A. Saydalimov.pdf"
//     },
//     {
//         name: "S-ID-04_T. Eshpulatov and A. Aminov",
//         src: "/assets/files/Acceptances KS/S-ID-04_T. Eshpulatov and A. Aminov.pdf"
//     },
//     {
//         name: "S-ID-05_A. Nurmatov, Sh. Jabborov, A. Sobirkhonov, O. Sharipov and D. Karibaeva",
//         src: "/assets/files/Acceptances KS/S-ID-05_A. Nurmatov, Sh. Jabborov, A. Sobirkhonov, O. Sharipov and D. Karibaeva.pdf"
//     },
//     {
//         name: "S-ID-06_M. Vafoeva and A. Abduazimov",
//         src: "/assets/files/Acceptances KS/S-ID-06_M. Vafoeva and A. Abduazimov.pdf"
//     },
//     {
//         name: "S-ID-07_M. Rakhimov, Kh. Muydinov and Sh. Madrakhimov",
//         src: "/assets/files/Acceptances KS/S-ID-07_M. Rakhimov, Kh. Muydinov and Sh. Madrakhimov.pdf"
//     },
//     {
//         name: "S-ID-08_E. Shakarboev, A. Berdibaev, S. Turgunov and Z. Abduqodirova",
//         src: "/assets/files/Acceptances KS/S-ID-08_E. Shakarboev, A. Berdibaev, S. Turgunov and Z. Abduqodirova.pdf"
//     },
//     {
//         name: "S-ID-09_S. Mavlanov, Sh. Kholov and A. Kamalova",
//         src: "/assets/files/Acceptances KS/S-ID-09_S. Mavlanov, Sh. Kholov and A. Kamalova.pdf"
//     },
//     {
//         name: "S-ID-10_I. Khalismatov, R. Zakirov, A. Zakirov and B Abdurakhmanov",
//         src: "/assets/files/Acceptances KS/S-ID-10_I. Khalismatov, R. Zakirov, A. Zakirov and B Abdurakhmanov.pdf"
//     },
//     {
//         name: "S-ID-11_N. Khodjayeva, I. Urazbaev, T. Sayidkhonov and Z. Ruzikulova",
//         src: "/assets/files/Acceptances KS/S-ID-11_N. Khodjayeva, I. Urazbaev, T. Sayidkhonov and Z. Ruzikulova.pdf"
//     },
//     {
//         name: "S-ID-12_O. Dormeshkin, G. Cherches and B. Khoshimov",
//         src: "/assets/files/Acceptances KS/S-ID-12_O. Dormeshkin, G. Cherches and B. Khoshimov.pdf"
//     },
//     {
//         name: "S-ID-13_N. Rizaev and S. Kadirov",
//         src: "/assets/files/Acceptances KS/S-ID-13_N. Rizaev and S. Kadirov.pdf"
//     },
//     {
//         name: "S-ID-14_R. Umarov, B. Azizov and M. Atabayev",
//         src: "/assets/files/Acceptances KS/S-ID-14_R. Umarov, B. Azizov and M. Atabayev.pdf"
//     },
//     {
//         name: "S-ID-15_U. Temirov, Sh. Namazov, N. Usanbaev, P. Ganiev and B. Zhurakulov",
//         src: "/assets/files/Acceptances KS/S-ID-15_U. Temirov, Sh. Namazov, N. Usanbaev, P. Ganiev and B. Zhurakulov.pdf"
//     },
//     {
//         name: "S-ID-02_A. Raupov, A. Aminov and L. Igamberdieva",
//         src: "/assets/files/Acceptances KS/S-ID-02_A. Raupov, A. Aminov and L. Igamberdieva.pdf"
//     },
//     {
//         name: "S-ID-16_N. Ravshanova and M. Ergashev",
//         src: "/assets/files/Acceptances KS/S-ID-16_N. Ravshanova and M. Ergashev.pdf"
//     },
//     {
//         name: "S-ID-17_М. Tashkuziev, T. Berdiev, A. Sherbekov and S. Ochilov",
//         src: "/assets/files/Acceptances KS/S-ID-17_М. Tashkuziev, T. Berdiev, A. Sherbekov and S. Ochilov.pdf"
//     },
//     {
//         name: "S-ID-18_B. Akhmedov and S. Yunusov",
//         src: "/assets/files/Acceptances KS/S-ID-18_B. Akhmedov and S. Yunusov.pdf"
//     },
//     {
//         name: "S-ID-19_O. Myachina, R. Kim, L. Mamasalieva, А. Rakhmonov, B. Pulatov and S Burieva",
//         src: "/assets/files/Acceptances KS/S-ID-19_O. Myachina, R. Kim, L. Mamasalieva, А. Rakhmonov, B. Pulatov and S Burieva.pdf"
//     },
//     {
//         name: "S-ID-20_A. Iminov, Sh. Kuziboev, O. Matchonov and M. Atabaev",
//         src: "/assets/files/Acceptances KS/S-ID-20_A. Iminov, Sh. Kuziboev, O. Matchonov and M. Atabaev.pdf"
//     },
//     {
//         name: "S-ID-21_M. Dosmukhamedova",
//         src: "/assets/files/Acceptances KS/S-ID-21_M. Dosmukhamedova.pdf"
//     },
//     {
//         name: "S-ID-22_S. Sharipov, A. Azizov and Z. Voqqosov",
//         src: "/assets/files/Acceptances KS/S-ID-22_S. Sharipov, A. Azizov and Z. Voqqosov.pdf"
//     },
//     {
//         name: "S-ID-23_F. Utemuratova, S. Kim, B. Kamilov, T. Kanatbayeva, M. Ummatova and M. Yuldashov",
//         src: "/assets/files/Acceptances KS/S-ID-23_F. Utemuratova, S. Kim, B. Kamilov, T. Kanatbayeva, M. Ummatova and M. Yuldashov.pdf"
//     },
//     {
//         name: "S-ID-24_N. Mullabaev, J. Sobirov, G. Urunova, M. Yuldashov and B. Kamilov",
//         src: "/assets/files/Acceptances KS/S-ID-24_N. Mullabaev, J. Sobirov, G. Urunova, M. Yuldashov and B. Kamilov.pdf"
//     },
//     {
//         name: "S-ID-25_U. Akromov, K. Ismailkhodjaev, M. Yuldashov and B. Kamilov",
//         src: "/assets/files/Acceptances KS/S-ID-25_U. Akromov, K. Ismailkhodjaev, M. Yuldashov and B. Kamilov.pdf"
//     },
//     {
//         name: "S-ID-26_D. Kholdarov, U. Sobitov, U. Mirzaev, M. Kholdarova, G. Sotiboldieva, S. Ahmadjonova and A. Sobirov",
//         src: "/assets/files/Acceptances KS/S-ID-26_D. Kholdarov, U. Sobitov, U. Mirzaev, M. Kholdarova, G. Sotiboldieva, S. Ahmadjonova and A. Sobirov.pdf"
//     },





//     {
//         name: "I-ID-1_S Abdurakhmonov, D Berdiyev, Sh Gulov, S Hazatqulov and M Bozorov",
//         src: "/assets/files/Acceptances IA_1/I-ID-1_S Abdurakhmonov, D Berdiyev, Sh Gulov, S Hazatqulov and M Bozorov.pdf"
//     },
//     {
//         name: "I-ID-2_Sh Kholdorov Lakshmi Gopakumar, K Katsura, Z Jabbarov, O Jobborov, T Shamsiddinov and A Khakimov",
//         src: "/assets/files/Acceptances IA_1/I-ID-2_Sh Kholdorov Lakshmi Gopakumar, K Katsura, Z Jabbarov, O Jobborov, T Shamsiddinov and A Khakimov.pdf"
//     }, {
//         name: "I-ID-3_Z Ganieva, M Khashimova, G Mirzaeva, K Rustamov and V Akhmedov",
//         src: "/assets/files/Acceptances IA_1/I-ID-3_Z Ganieva, M Khashimova, G Mirzaeva, K Rustamov and V Akhmedov.pdf"
//     },
//     {
//         name: "I-ID-4_M Isagaliev, Z Isomiddinov and M Obidov",
//         src: "/assets/files/Acceptances IA_1/I-ID-4_M Isagaliev, Z Isomiddinov and M Obidov.pdf"
//     },
//     {
//         name: "I-ID-5_A Ashurov, G Saydalieva, I Xasanov",
//         src: "/assets/files/Acceptances IA_1/I-ID-5_A Ashurov, G Saydalieva, I Xasanov.pdf"
//     },
//     {
//         name: "I-ID-6_G Saydalieva",
//         src: "/assets/files/Acceptances IA_1/I-ID-6_G Saydalieva.pdf"
//     },
//     {
//         name: "I-ID-7_R Oymatov, M Rakhimov, M Baxriyev, G Aminova, I Menlimuratova",
//         src: "/assets/files/Acceptances IA_1/I-ID-7_R Oymatov, M Rakhimov, M Baxriyev, G Aminova, I Menlimuratova.pdf"
//     },
//     {
//         name: "I-ID-8_R Oymatov, M Reymov, K Toxirov, M Baxriyev and R Maksudov",
//         src: "/assets/files/Acceptances IA_1/I-ID-8_R Oymatov, M Reymov, K Toxirov, M Baxriyev and R Maksudov.pdf"
//     }, {
//         name: "I-ID-11_G Rakhimova, B Niyazaliyev, S Asatova and S Bekmuratov",
//         src: "/assets/files/Acceptances IA_1/I-ID-11_G Rakhimova, B Niyazaliyev, S Asatova and S Bekmuratov.pdf"
//     },
//     {
//         name: "I-ID-12_A  Mamadaliev, Kh. Kushiev, Z Abdullaeva, T Djuraev",
//         src: "/assets/files/Acceptances IA_1/I-ID-12_A  Mamadaliev, Kh. Kushiev, Z Abdullaeva, T Djuraev.pdf"
//     },
//     {
//         name: "I-ID-13_S Avezbaev, Ç Mukumov, S Abduqodirova, Z Khafizova and I Shukurbekov",
//         src: "/assets/files/Acceptances IA_1/I-ID-13_S Avezbaev, Ç Mukumov, S Abduqodirova, Z Khafizova and I Shukurbekov.pdf"
//     },
//     {
//         name: "I-ID-14_Z Jabbarov, B Jobborov, Sh Kholdorov and T Abdrakhmanov",
//         src: "/assets/files/Acceptances IA_1/I-ID-14_Z Jabbarov, B Jobborov, Sh Kholdorov and T Abdrakhmanov.pdf"
//     },
//     {
//         name: "I-ID-15_Yu Usmanov",
//         src: "/assets/files/Acceptances IA_1/I-ID-15_Yu Usmanov.pdf"
//     },
//     {
//         name: "I-ID-16_M Axmedov, O Sharipov, X Karimova",
//         src: "/assets/files/Acceptances IA_1/I-ID-16_M Axmedov, O Sharipov, X Karimova.pdf"
//     }, {
//         name: "I-ID-17_S Sidikov, O Ergasheva, M Ermatova",
//         src: "/assets/files/Acceptances IA_1/I-ID-17_S Sidikov, O Ergasheva, M Ermatova.pdf"
//     },
//     {
//         name: "I-ID-19_S Avezbaev , J Askarov, M Majidov and M Kenjaboev",
//         src: "/assets/files/Acceptances IA_1/I-ID-19_S Avezbaev , J Askarov, M Majidov and M Kenjaboev.pdf"
//     },
//     {
//         name: "I-ID-20_A Batykova, B Tuleev, T Tuleev, A Babajanov, A Zhumabekova",
//         src: "/assets/files/Acceptances IA_1/I-ID-20_A Batykova, B Tuleev, T Tuleev, A Babajanov, A Zhumabekova.pdf"
//     },
//     {
//         name: "I-ID-21_M Mazirov, N Matyuk, V Polin, O Khakberdiyev, O Savoskina",
//         src: "/assets/files/Acceptances IA_1/I-ID-21_M Mazirov, N Matyuk, V Polin, O Khakberdiyev, O Savoskina.pdf"
//     },
//     {
//         name: "I-ID-23_Salokhiddinov A.T, Savitsky A.G., D. McKinney, Ashirova O",
//         src: "/assets/files/Acceptances IA_1/I-ID-23_Salokhiddinov A.T, Savitsky A.G., D. McKinney, Ashirova O.pdf"
//     },
//     {
//         name: "I-ID-24_N Ravshanova M Ergashev",
//         src: "/assets/files/Acceptances IA_1/I-ID-24_N Ravshanova M Ergashev.pdf"
//     }, {
//         name: "I-ID-25_I Khalismatov, R Zakirov, A Zakirov and B Abdurakhmanov",
//         src: "/assets/files/Acceptances IA_1/I-ID-25_I Khalismatov, R Zakirov, A Zakirov and B Abdurakhmanov.pdf"
//     },
//     {
//         name: "I-ID-26_B Janibekov, M Turapov, A Jumagulov and U Najmiddinov",
//         src: "/assets/files/Acceptances IA_1/I-ID-26_B Janibekov, M Turapov, A Jumagulov and U Najmiddinov.pdf"
//     },
//     {
//         name: "I-ID-27_M Tashkuiev, T Berdiev, A Sherbekov and S Ochilov",
//         src: "/assets/files/Acceptances IA_1/I-ID-27_M Tashkuiev, T Berdiev, A Sherbekov and S Ochilov.pdf"
//     },
//     {
//         name: "I-ID-28_U Temirov, Sh Namazov, N Usanbaev, P Ganiev  and B Zhurakulov",
//         src: "/assets/files/Acceptances IA_1/I-ID-28_U Temirov, Sh Namazov, N Usanbaev, P Ganiev  and B Zhurakulov.pdf"
//     },
//     {
//         name: "I-ID-29_S Sidikov,  Z Abdushukurova, S Zakirova and  S Yunusova",
//         src: "/assets/files/Acceptances IA_1/I-ID-29_S Sidikov,  Z Abdushukurova, S Zakirova and  S Yunusova.pdf"
//     },
//     {
//         name: "I-ID-30_î Dauletmuradov, G Djalilova, V Sherimbetov, X Raximov and M Kazakova",
//         src: "/assets/files/Acceptances IA_1/I-ID-30_î Dauletmuradov, G Djalilova, V Sherimbetov, X Raximov and M Kazakova.pdf"
//     }, {
//         name: "I-ID-32_G Nabieva, D Makhkamova",
//         src: "/assets/files/Acceptances IA_1/I-ID-32_G Nabieva, D Makhkamova.pdf"
//     },
//     {
//         name: "I-ID-33_S Abdurahmanov, N Umarov, F Gulmurodov and  Sh Prenov",
//         src: "/assets/files/Acceptances IA_1/I-ID-33_S Abdurahmanov, N Umarov, F Gulmurodov and  Sh Prenov.pdf"
//     },
//     {
//         name: "I-ID-34_M Mansurkhodjaeva, Z Ganiteva and Z Akhmedova",
//         src: "/assets/files/Acceptances IA_1/I-ID-34_M Mansurkhodjaeva, Z Ganiteva and Z Akhmedova.pdf"
//     },
//     {
//         name: "I-ID-35_A Anorbaev, A Raxmanov",
//         src: "/assets/files/Acceptances IA_1/I-ID-35_A Anorbaev, A Raxmanov.pdf"
//     },
//     {
//         name: "I-ID-36_R Qurvantayev, N Xakimova, N Soliyeva",
//         src: "/assets/files/Acceptances IA_1/I-ID-36_R Qurvantayev, N Xakimova, N Soliyeva.pdf"
//     },
//     {
//         name: "I-ID-37_A Anorbaev, A Raxmanov",
//         src: "/assets/files/Acceptances IA_1/I-ID-37_A Anorbaev, A Raxmanov.pdf"
//     }, {
//         name: "I-ID-38_D Kodirova, G Djalilova, M Saidova, A Nurullayev and X Allayarov",
//         src: "/assets/files/Acceptances IA_1/I-ID-38_D Kodirova, G Djalilova, M Saidova, A Nurullayev and X Allayarov.pdf"
//     },
//     {
//         name: "I-ID-39_M Mamasolieva,  L Gafurova, I Hudoynazarov, I Ganiyeva and Kh Mukhammadaliev",
//         src: "/assets/files/Acceptances IA_1/I-ID-39_M Mamasolieva,  L Gafurova, I Hudoynazarov, I Ganiyeva and Kh Mukhammadaliev.pdf"
//     },
//     {
//         name: "I-ID-40_M Saidova, L Gafurova, D Kodirova and N Shadiyeva",
//         src: "/assets/files/Acceptances IA_1/I-ID-40_M Saidova, L Gafurova, D Kodirova and N Shadiyeva.pdf"
//     },
//     {
//         name: "I-ID-41_Z Khafizova, N Khojaeva",
//         src: "/assets/files/Acceptances IA_1/I-ID-41_Z Khafizova, N Khojaeva.pdf"
//     },

//     {
//         name: "I-ID-42_D Burxanova, M Urmanova",
//         src: "/assets/files/Acceptances IA_1/I-ID-42_D Burxanova, M Urmanova.pdf"
//     },
//     {
//         name: "I-ID-43_A Ismonov, N Abdurakhm«nov, O Mamajanova, N Kalandarov, I Yuldashev and M Pulatov",
//         src: "/assets/files/Acceptances IA_1/I-ID-43_A Ismonov, N Abdurakhm«nov, O Mamajanova, N Kalandarov, I Yuldashev and M Pulatov.pdf"
//     }, {
//         name: "I-ID-45_O Myachina, R Kim, L Mamasalieva, Ç Rakhmonov, B Pulatov and S Burieva",
//         src: "/assets/files/Acceptances IA_1/I-ID-45_O Myachina, R Kim, L Mamasalieva, Ç Rakhmonov, B Pulatov and S Burieva.pdf"
//     },
//     {
//         name: "I-ID-46_Sh Esanbaev",
//         src: "/assets/files/Acceptances IA_1/I-ID-46_Sh Esanbaev.pdf"
//     },
//     {
//         name: "I-ID-47_N Narkabulova, Z Abdikayumov, Z Jabborov and A Safarov",
//         src: "/assets/files/Acceptances IA_1/I-ID-47_N Narkabulova, Z Abdikayumov, Z Jabborov and A Safarov.pdf"
//     },
//     {
//         name: "I-ID-49_T Ishbobaev, A Tangirov, B Urunov and Z Xaydarov",
//         src: "/assets/files/Acceptances IA_1/I-ID-49_T Ishbobaev, A Tangirov, B Urunov and Z Xaydarov.pdf"
//     },
//     {
//         name: "I-ID-50_G Raimbaeva, G Mirxaydarova",
//         src: "/assets/files/Acceptances IA_1/I-ID-50_G Raimbaeva, G Mirxaydarova.pdf"
//     },
//     {
//         name: "S-ID-48_N Namozov, M Saidova, I Aslanov, X Rasulov B Teshaboev and ",
//         src: "/assets/files/Acceptances IA_1/S-ID-48_N Namozov, M Saidova, I Aslanov, X Rasulov B Teshaboev and .pdf"
//     }

// ]

const CERTIFICATES = () => {
    const STATISTICS_URL = "http://127.0.0.1:8000/api/certificates/";
    const [statistics, setStatistics] = useState([]);
    

    // const getStatistics = useCallback(
    //     async () => {
    //         baseApi.fetchAll(STATISTICS_URL)
    //         .then(res => {
    //             setStatistics(res.data)
    //         })
    //     }, [])


    const getStatistics = () => {
        axios.get(STATISTICS_URL)
        .then ((response) => {
            setStatistics(response.data);
            // console.log(statistics)
        })
        .catch(error => console.error(`Eror:  ${error}`));
    }

    useEffect(() => {
        getStatistics();
        // setData();
        // setValue();
    }, [])


    const [value, setValue] = useState('');
    const [data, setData] = useState(statistics);

    return (
        <div style={{ backgroundColor: "#fff", width: "100%", height: "100%" }}>
            <div className='container-cer'>
                <Row gutter={24}>
                    <Col span={24}>
                        <h1 style={{ textAlign: "center" }} className='cer-title'>CERTIFICATES</h1>
                    </Col>
                    
                </Row>
                <Row className='search' justify='center' gutter={24}>
                    <Col span={10}>
                        <Search
                            placeholder="Search...."
                            allowClear
                            enterButton="Search"
                            size="large"
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Col>
                </Row>
                <div className="site-card-wrapper">
                    <Row className='cardd-parent' gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        {
                            // console.log(statistics)
                            
                            statistics.filter(card => {
                                if (value === "") {
                                    
                                    return card;
                                } else if (card.name.toLowerCase().includes(value.toLowerCase())) {
                                    return card;
                                }
                            }).map((item, inx) => (
                                console.log(item),
                                <Col className='gutter-row cardd-col' key={inx} span={8}>
                                    <Card className='cardd' title={item.certificateName}>
                                        <a className='cardd-link' href={item.files}>Yuklab olish</a>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default CERTIFICATES;
